import React from "react";
import ReactDOM from "react-dom/client";
import { Authenticator } from "@aws-amplify/ui-react";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import reportWebVitals from "./reportWebVitals";

//import for auth
import { Amplify } from "aws-amplify";
import config from "./aws-exports";

import App from "./App";
import "./index.css";

Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Authenticator.Provider>
    <App />
  </Authenticator.Provider>
);

reportWebVitals();
import { useState, useEffect, useRef } from "react";
import { Auth } from "aws-amplify";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { Switch, Select, MenuItem } from "@mui/material";
import { Stack } from "@mui/material";
import { Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { I18n } from "aws-amplify";
import { BarcodeScanner } from "../scanner/BarcodeScanner";

const tagRule = new RegExp(/^BB\d+$/m);

function AddDataPopup(props) {
  const [newData, setNewData] = useState({
    tag_id: "",
    serial_number: "",
    customer_serial_number: "",
    username: "",
    nonEpta: "",
    manufacturerID: "",
    classificationID: "",
    organizationID: localStorage.getItem("organization"),
    year: "",
  });
  const serialRef = useRef();
  const customerSerialRef = useRef();
  const [showSelects, setShowSelects] = useState(false);
  const [tagIdFocused, setTagIdFocused] = useState(false);
  const [serNumFocused, setSerNumFocused] = useState(false);
  const [serialRule, setSerialRule] = useState(null);
  const [serialPlaceholder, setSerialPlaceholder] = useState("XXXXXXX");
  const [serialNumberError, setSerialNumberError] = useState(null);
  const [tagIDError, setTagIDError] = useState(null);
  const [barcodeScanner, setBarcodeScanner] = useState({
    visible: false,
    callback: null,
  });

  const handleToggle = (event, newValue) => {
    setShowSelects(newValue);
    setNewData((prevState) => ({
      ...prevState,
      nonEpta: newValue,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOrgChange = (event) => {
    setNewData((prevState) => ({
      ...prevState,
      organizationID: event.target.value,
    }));
    localStorage.setItem("organization", event.target.value);
  };

  const checkSerial = (newSerial) => {
    if (serialRule) {
      if (!serialRule.test(newSerial)) {
        setSerialNumberError(I18n.get("ser_format_err"));
      } else {
        setSerialNumberError(null);
      }
    }
  };
  const checkTagID = (newTagID) => {
    if (!tagRule.test(newTagID)) {
      setTagIDError(I18n.get("tag_format_err"));
    } else {
      setTagIDError(null);
    }
  };

  const handleDateChange = (date) => {
    if (date) {
      setNewData((prevState) => ({
        ...prevState,
        year: date.year(),
      }));
    }
  };

  const createSerialPlaceholder = (rule) => {
    if (rule && rule.toString().includes("\\d")) {
      rule = rule.toString();
      const idx = rule.indexOf("\\d");
      const sub = rule.substring(2, idx);
      const idxOfMin = rule.indexOf("{") + 1;
      const idxOfMax = rule.indexOf(",");
      const minDigits = Number(rule.substring(idxOfMin, idxOfMax));
      const placeholder = sub + "X".repeat(minDigits);
      setSerialPlaceholder(placeholder);
    } else {
      setSerialPlaceholder("XXXXXXX");
    }
  };

  const handleSave = async () => {
    props.onSave(newData);
    props.onClose();
  };

  const showScanner = (targetName) => {
    const onScanCallback = (value) => {
      const event = {
        target: {
          name: targetName,
          value: value,
        },
      };
      handleInputChange(event);
      setTimeout(() => {
        if (targetName === "tag_id") {
          serialRef.current.focus();
        } else if (targetName === "serial_number") {
          customerSerialRef.current.focus();
        }
      }, 100);
      setBarcodeScanner({ visible: false });
    };
    setBarcodeScanner({ visible: true, callback: onScanCallback });
  };

  const correctTagID =
    newData["tag_id"] !== undefined &&
    newData["tag_id"] !== null &&
    newData["tag_id"].length > 0;

  const correctSerNum =
    newData["serial_number"] !== undefined &&
    newData["serial_number"] !== null &&
    newData["serial_number"].length > 0;

  useEffect(() => {
    const setUser = async () => {
      const { username } = await Auth.currentAuthenticatedUser();
      setNewData((prevState) => ({
        ...prevState,
        username: username,
      }));
    };
    setUser();
  }, []);

  useEffect(() => {
    if (props.nonEptaDetails.data && props.nonEptaDetails.data.organization) {
      const selectedOrg = props.nonEptaDetails.data.organization.find(
        (org) => org?.id?.toString() === newData?.organizationID?.toString()
      );
      let pattern, flags;
      if (selectedOrg && selectedOrg.rule) {
        pattern = selectedOrg.rule.slice(1, selectedOrg.rule.lastIndexOf("/"));
        flags = selectedOrg.rule.slice(selectedOrg.rule.lastIndexOf("/") + 1);
      } else {
        pattern = ".*";
        flags = "m";
      }
      setSerialRule(new RegExp(pattern, flags));
    }
  }, [props.nonEptaDetails.data, newData.organizationID]);

  useEffect(() => {
    createSerialPlaceholder(serialRule);
  }, [serialRule]);

  useEffect(() => {
    checkSerial(newData.serial_number);
    checkTagID(newData.tag_id);
  }, [newData.serial_number, newData.tag_id, serialRule]);

  return props.open ? (
    <Dialog fullWidth maxWidth="sm" open={props.open} onClose={props.onClose}>
      <DialogTitle>
        {I18n.get("add_tag")}
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form>
          {barcodeScanner.visible && (
            <BarcodeScanner onScan={barcodeScanner.callback} />
          )}
          <FormControl fullWidth key={"organizationID"} margin="dense">
            <InputLabel>{I18n.get("organization")}</InputLabel>
            <Select
              value={newData["organizationID"] || ""}
              name={"organizationID"}
              label={I18n.get("organization")}
              onChange={handleOrgChange}
            >
              <MenuItem value="">{I18n.get("none")}</MenuItem>
              {props.nonEptaDetails.data &&
                props.nonEptaDetails.data.organization &&
                props.nonEptaDetails.data.organization.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            name="tag_id"
            label={I18n.get("tag_id")}
            placeholder="BBXXXXXX"
            type="text"
            value={newData.tag_id}
            onChange={handleInputChange}
            fullWidth
            error={tagIdFocused && (!correctTagID || tagIDError || false)}
            onFocus={() => setTagIdFocused(true)}
            onBlur={() => setTagIdFocused(false)}
            helperText={
              !correctTagID
                ? I18n.get("mandatory_field")
                : tagIDError
                ? tagIDError
                : ""
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <QrCodeScannerIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => showScanner("tag_id")}
                  />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="dense"
            name="serial_number"
            label={I18n.get("serial_number")}
            inputRef={serialRef}
            placeholder={serialPlaceholder}
            type="text"
            value={newData.serial_number}
            onChange={handleInputChange}
            fullWidth
            error={
              serNumFocused && (!correctSerNum || serialNumberError || false)
            }
            onFocus={() => setSerNumFocused(true)}
            onBlur={() => setSerNumFocused(false)}
            helperText={
              !correctSerNum
                ? I18n.get("mandatory_field")
                : serialNumberError
                ? serialNumberError
                : ""
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <QrCodeScannerIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => showScanner("serial_number")}
                  />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="dense"
            name="customer_serial_number"
            inputRef={customerSerialRef}
            label={I18n.get("customer_serial_number")}
            type="text"
            value={newData.customer_serial_number}
            onChange={handleInputChange}
            fullWidth
          />
          <Box mt={2}>
            <Stack direction="row" spacing={2} alignItems="center">
              <div>{I18n.get("epta")}</div>
              <Switch
                name="nonEpta"
                label="nonEpta"
                checked={showSelects}
                onChange={handleToggle}
                inputProps={{ "aria-label": "select visibility" }}
              />
              <div>{I18n.get("not_epta")}</div>
            </Stack>
          </Box>
          {showSelects && (
            <Box mt={2}>
              <FormControl fullWidth key={"manufacturerID"} margin="dense">
                <InputLabel>{I18n.get("manufacturer")}</InputLabel>
                <Select
                  value={newData["manufacturerID"] || ""}
                  name={"manufacturerID"}
                  label={"manufacturerID"}
                  onChange={handleInputChange}
                >
                  <MenuItem value="">{I18n.get("none")}</MenuItem>
                  {props.nonEptaDetails.data.manufacturer.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Box mt={2}>
                <FormControl fullWidth key={"classificationID"} margin="dense">
                  <InputLabel>{I18n.get("classification")}</InputLabel>
                  <Select
                    value={newData["classificationID"] || ""}
                    name={"classificationID"}
                    label={"classificationID"}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="">{I18n.get("none")}</MenuItem>
                    {props.nonEptaDetails.data.classification.map(
                      (item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.value}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Box>
              <Box mt={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name={"Year"}
                    label={I18n.get("year")}
                    onChange={handleDateChange}
                    views={["year"]}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          )}
          <Button
            sx={{ mt: 2 }}
            onClick={handleSave}
            variant="contained"
            disabled={
              !correctTagID ||
              !correctSerNum ||
              serialNumberError ||
              tagIDError ||
              false
            }
          >
            {I18n.get("save")}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  ) : null;
}

export default AddDataPopup;

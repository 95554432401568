import { Authenticator } from "@aws-amplify/ui-react";

import { Header } from "../components/Login/Header";
import { SignInHeader } from "../components/Login/SignInHeader";
import { SignInFooter } from "../components/Login/SignInFooter";
import Box from "@mui/material/Box";

import "../assets/css/amplify-styles.css";

var image = require("../assets/login-img/LoginBackground.jpg");
//   var logo = require('../img/LoginLogo.png')

const components = {
  Header,
  SignIn: {
    Header: SignInHeader,
    Footer: SignInFooter,
  },
  // Footer
};

export function Login() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      objectfit="cover"
      sx={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
      }}
    >
      <Authenticator
        components={components}
        hideSignUp={true}
      >
        {({ signOut, user }) => (
          <main>
            <h1>Hello {user.username}</h1>
            <button onClick={signOut}>Sign out</button>
          </main>
        )}
      </Authenticator>
    </Box>
  );
}

import * as React from "react";
import { Box, Typography, Paper, useTheme } from "@mui/material";
import DataArrayIcon from "@mui/icons-material/DataArray";
import { styled } from "@mui/system";
import { I18n } from "aws-amplify";
const RootBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
}));

const MessageBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  margin: theme.spacing(3),
}));

const NoData = () => {
  const theme = useTheme();
  return (
    <RootBox theme={theme}>
      <MessageBox theme={theme}>
        <DataArrayIcon
          sx={{
            fontSize: "3rem",
            color: "secondary.main",
          }}
        />
        <Typography variant="h5" component="h2">
          {I18n.get("No Data !")}
        </Typography>
        <Typography variant="subtitle1">
          {I18n.get("There is no data to display at this moment.")}
        </Typography>
      </MessageBox>
    </RootBox>
  );
};

export default NoData;

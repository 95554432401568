import { Box, Typography, SvgIcon, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Paper from "@mui/material/Paper";

const RootBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

const MessageBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  margin: theme.spacing(3),
}));

const Icon = styled(SvgIcon)(({ theme }) => ({
  fontSize: "3rem",
  color: theme.palette.warning.dark,
}));

const AdminAlert = () => {
  const theme = useTheme();

  return (
    <Paper sx={{ m: 3, p: 3, backgroundColor: theme.palette.mode === "light" ? "#fff4e6" : "#191307" }}>
      <RootBox theme={theme}>
        <MessageBox theme={theme}>
          <Icon component={WarningAmberIcon} theme={theme} />
          <Typography variant="h5" component="h2" color={ theme.palette.mode === "light" ? "#7f582d" : "#e4d2aa"}>
            Access Denied
          </Typography>
          <Typography variant="subtitle1">
            Sorry, You don't have access to this page
          </Typography>
        </MessageBox>
      </RootBox>
    </Paper>
  );
};

export default AdminAlert;
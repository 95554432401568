import React, { useState, useEffect, useReducer } from "react";
import { API } from "aws-amplify";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tooltip from "@mui/material/Tooltip";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import NoData from "../components/cards/NoData.js";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { useParams, useLocation } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { createSvgIcon } from "@mui/material/utils";
import dayjs from "dayjs";
import { I18n } from "aws-amplify";
import Papa from "papaparse";
import it from "dayjs/locale/it"; //should be here to apply localization

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";

//The main data reducer of the data grid
const dataReducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return { fetched: false };
    case "DATA_RECEIVED":
      return { data: action.payload, fetched: true };
    default:
      return state;
  }
};

function Tags() {
  const [state, dispatch] = useReducer(dataReducer, {
    data: [],
    fetched: false,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const theme = useTheme();
  const mobileDevice = !useMediaQuery(theme.breakpoints.up("md"));
  const [order, setOrder] = useState("asc");
  const { tagID } = useParams();
  const location = useLocation();
  const defaultStartDate = dayjs(dayjs().startOf("month"));
  const defaultEndDate = dayjs(dayjs().endOf("month"));
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [tagIDValue, setTagIDValue] = useState(tagID ? tagID : I18n.get("All"));
  const [serialNumberValue, setSerialNumberValue] = useState(I18n.get("All"));
  const [eventTypeValue, setEventTypeValue] = useState(I18n.get("All"));
  const [eventTypes, setEventTypes] = useState([]);
  const headerCells = [
    {
      id: "tag_id",
      label: I18n.get("tag_id"),
      align: "left",
      style: { width: mobileDevice ? "90" : "10%" },
      show: true,
    },
    {
      id: "serial_number",
      label: I18n.get("serial_number"),
      align: "left",
      style: { width: mobileDevice ? "90" : "10%" },
      show: true,
    },
    {
      id: "description",
      label: I18n.get("event"),
      style: { width: "10%" },
      align: "left",
      show: true,
    },
    {
      id: "organization",
      label: I18n.get("organization"),
      style: { width: "10%" },
      align: "center",
      show: !mobileDevice,
    },
    {
      id: "created_by_user",
      label: I18n.get("created_by_user"),
      style: { width: "20%" },
      align: "center",
      show: !mobileDevice,
    },
    {
      id: "event_timestamp",
      label: I18n.get("event_timestamp"),
      style: { width: "20%" },
      align: "left",
      show: !mobileDevice,
    },
    {
      id: "customer_serial_number",
      label: I18n.get("customer_serial_number"),
      style: { width: "10%" },
      align: "left",
      show: !mobileDevice,
    },
  ];

  const fetchData = async () => {
    dispatch({ type: "LOADING" });
    let queryParams = {
      queryStringParameters: {
        tag_id: tagIDValue && tagIDValue !== I18n.get("All") ? tagIDValue : "",
        serial_number:
          serialNumberValue && serialNumberValue !== I18n.get("All")
            ? serialNumberValue
            : "",
        event_type:
          eventTypeValue && eventTypeValue !== I18n.get("All")
            ? eventTypeValue
            : "",
        date_start: startDate.format("YYYY-MM-DD"),
        date_end: endDate.format("YYYY-MM-DD"),
      },
    };

    const response = await API.get(
      "nfctaglinks",
      "/getNFCTagEvents",
      queryParams
    );
    const parsedResponse = JSON.parse(response);
    parsedResponse.forEach((item) => {
      if (!item.event_timestamp.includes(".")) {
        item.event_timestamp = item.event_timestamp + ".000000";
      }
      const timestamp = new Date(item.event_timestamp + "UTC");
      const formattedTimestamp = dayjs(timestamp).format("YYYY-MM-DD HH:mm:ss");
      item.event_timestamp = formattedTimestamp;
    });
    parsedResponse.sort((a, b) =>
      a.event_timestamp < b.event_timestamp ? 1 : -1
    );
    dispatch({ type: "DATA_RECEIVED", payload: parsedResponse });
  };

  const fetchEventTypes = async () => {
    let queryParams = {};

    const response = await API.get(
      "nfctaglinks",
      "/getEventTypes",
      queryParams
    );
    const parsedResponse = JSON.parse(response);
    setEventTypes(parsedResponse);
  };

  useEffect(() => {
    if (location.pathname === "/admin/events") {
      setTagIDValue(I18n.get("All"));
    } else {
      setTagIDValue(tagID);
    }
    // First API call on the page
    fetchData();
  }, []);

  useEffect(() => {
    fetchEventTypes();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const reorder = () => {
    dispatch({ type: "LOADING" });
    let newData = [...state.data];
    if (order === "asc") {
      newData.sort((a, b) => (a.event_timestamp > b.event_timestamp ? 1 : -1));
      setOrder("desc");
    } else {
      newData.sort((a, b) => (a.event_timestamp < b.event_timestamp ? 1 : -1));
      setOrder("asc");
    }

    // Update the state with the new sorted data
    dispatch({ type: "DATA_RECEIVED", payload: newData });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const applyFiltering = function (value) {
    if (searchText) {
      const searchTextLower = searchText.toLowerCase();
      return Object.values(value).some((val) =>
        String(val).toLowerCase().includes(searchTextLower)
      );
    }

    return true;
  };

  const searchChanged = function (event) {
    setSearchText(event.target.value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (date.isAfter(endDate)) {
      setStartDateError(I18n.get("start_date_error_msg"));
    } else {
      setStartDateError("");
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    if (date.isBefore(startDate)) {
      setEndDateError(I18n.get("end_date_error_msg"));
    } else {
      setEndDateError("");
    }
  };

  // Function to handle the changes in the TextField
  const handleTagIDChange = (event) => {
    setTagIDValue(event.target.value);
  };

  const handleSerialChange = (event) => {
    setSerialNumberValue(event.target.value);
  };
  const handleEventTypeChange = (event) => {
    setEventTypeValue(event.target.value);
  };

  const handleSearch = () => {
    if (startDateError === "" && endDateError === "") {
      fetchData();
    }
  };

  const handleExport = () => {
    const columnOrder = [
      "organization",
      "created_by_user",
      "description",
      "event_date",
      "event_timestamp",
      "tag_id",
      "serial_number",
      "customer_serial_number",
    ];

    // add short date field
    const data = state.data.map((row) => {
      row.event_date = new Date(row.event_timestamp).toLocaleDateString(
        "en-CA"
      );
      return row;
    });

    // Rearrange the data based on the new column order
    const rearrangedData = data.map((row) => {
      const newRow = {};
      columnOrder.forEach((column) => {
        newRow[column] = row[column];
      });
      return newRow;
    });

    // Generate the CSV
    const csv = Papa.unparse(rearrangedData);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "export.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const ExportIcon = createSvgIcon(
    <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
    "SaveAlt"
  );

  const buttonBaseProps = {
    color: "primary",
    size: "small",
    startIcon: <ExportIcon />,
  };

  return (
    <>
      <Paper elevation={0} sx={{ padding: "0.5rem" }}>
        <Box sx={{ height: "100%", width: "100%" }}>
          <Toolbar
            sx={{
              justifyContent: "space-between",
              minHeight: "1rem",
              ml: -2,
              pt: 2,
              pb: 2,
            }}
          >
            <Stack spacing={2} direction={mobileDevice ? "column" : "row"}>
              <TextField
                label={I18n.get("tag_id")}
                value={tagIDValue}
                onChange={handleTagIDChange}
              />
              <TextField
                label={I18n.get("serial_number")}
                value={serialNumberValue}
                onChange={handleSerialChange}
              />
              <FormControl
                sx={{ minWidth: 200 }}
                key={"eventType"}
                margin="dense"
              >
                <InputLabel>{I18n.get("event_type")}</InputLabel>
                <Select
                  value={eventTypeValue}
                  name={"eventType"}
                  label={I18n.get("event_type")}
                  onChange={handleEventTypeChange}
                >
                  <MenuItem key={0} value={I18n.get("All")}>
                    {I18n.get("All")}
                  </MenuItem>
                  {eventTypes.map((eventType) => (
                    <MenuItem key={eventType.id} value={eventType.id}>
                      {eventType.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LocalizationProvider
                adapterLocale={
                  localStorage.getItem("language") === "it" ? "it" : "en"
                }
                dateAdapter={AdapterDayjs}
              >
                <Stack spacing={2} direction="row">
                  <DatePicker
                    label={I18n.get("start_date")}
                    format="YYYY/MM/DD"
                    value={startDate}
                    onChange={handleStartDateChange}
                    onError={(newError) => setStartDateError(newError)}
                    slotProps={{
                      textField: {
                        helperText: startDateError,
                        error: !!startDateError,
                      },
                    }}
                  />
                  <DatePicker
                    label={I18n.get("end_date")}
                    format="YYYY/MM/DD"
                    value={endDate}
                    onChange={handleEndDateChange}
                    onError={(newError) => setEndDateError(newError)}
                    slotProps={{
                      textField: {
                        helperText: endDateError,
                        error: !!endDateError,
                      },
                    }}
                  />
                </Stack>
              </LocalizationProvider>
              <Stack direction="row">
                <IconButton>
                  <SearchIcon onClick={handleSearch} />
                </IconButton>
                <Button {...buttonBaseProps} onClick={handleExport}>
                  {I18n.get("export")}
                </Button>
              </Stack>
            </Stack>
          </Toolbar>
          {!state.fetched ? (
            <Stack alignItems="center" sx={{ height: "100%" }}>
              <Skeleton
                variant="rounded"
                height={40}
                width="100%"
                sx={{ margin: "2px", marginBottom: "5px" }}
              />
              <Divider sx={{ width: "100%" }} />
              <Skeleton
                variant="rounded"
                height={30}
                width="100%"
                sx={{ margin: "2px", marginTop: "5px" }}
              />
              <Skeleton
                variant="rounded"
                height={30}
                width="100%"
                sx={{ margin: "2px" }}
              />
              <Skeleton
                variant="rounded"
                height={30}
                width="100%"
                sx={{ margin: "2px" }}
              />
            </Stack>
          ) : (
            <>
              <TableContainer>
                <Table size="small" sx={{ height: "100%", width: "100%" }}>
                  <TableHead>
                    <TableRow key="header">
                      {headerCells.map(
                        (column) =>
                          column.show && (
                            <TableCell
                              key={column.id}
                              style={column.style}
                              align={column.align}
                            >
                              <strong>{column.label}</strong>
                              {column.id === "event_timestamp" && (
                                <IconButton onClick={reorder}>
                                  <Tooltip
                                    arrow
                                    title={I18n.get("order_items")}
                                  >
                                    <div>
                                      <ArrowDownwardIcon
                                        style={{
                                          fontSize: 20,
                                          color: "grey",
                                          display:
                                            order === "asc" ? "inline" : "none",
                                        }}
                                      />
                                      <ArrowUpwardIcon
                                        style={{
                                          fontSize: 20,
                                          color: "grey",
                                          display:
                                            order === "asc" ? "none" : "inline",
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                </IconButton>
                              )}
                            </TableCell>
                          )
                      )}
                    </TableRow>
                  </TableHead>

                  {state.data.length === 0 ? (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={headerCells.length}>
                          <NoData />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {state.data
                        .filter(applyFiltering)
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <TableRow key={index}>
                            {headerCells.map((column) => {
                              if (column.show) {
                                if (column.id === "non_epta") {
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      <Switch
                                        checked={!row[column.id]}
                                        disabled
                                      />
                                    </TableCell>
                                  );
                                } else {
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {row[column.id]}
                                    </TableCell>
                                  );
                                }
                              }

                              return null;
                            })}
                          </TableRow>
                        ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={state.data.filter(applyFiltering).length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={I18n.get("rows_per_page")}
                labelDisplayedRows={({ from, to, count }) => {
                  return "" + from + "-" + to + ` ${I18n.get("of")} ` + count;
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Box>
      </Paper>
    </>
  );
}

export default Tags;

import React, { useState, useEffect } from "react";

import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation, useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { I18n } from "aws-amplify";
import LanguageIcon from "@mui/icons-material/Language";
function Header({ toggleDarkMode, signout }) {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [showBack, setShowBack] = useState(false);
  const mobileDevice = !useMediaQuery(theme.breakpoints.up("md"));
  const lastPath = location.pathname.split("/").pop();
  const [anchorEl, setAnchorEl] = useState(null);
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "en"
  );
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    lastPath !== "ready" ? setShowBack(true) : setShowBack(false);
  }, [lastPath]);

  const getHeaderTitle = () => {
    const pathArray = location.pathname.split("/");

    if (pathArray.includes("tags")) {
      return I18n.get("tags");
    } else if (pathArray.includes("users")) {
      return I18n.get("user_management");
    } else if (pathArray.includes("events")) {
      return I18n.get("events");
    } else {
      return I18n.get("no_name");
    }
  };
  const handleBack = () => {
    navigate(-1);
  };

  const handleLanguageToggle = () => {
    const newLanguage = language === "en" ? "it" : "en";
    I18n.setLanguage(newLanguage);
    setLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
    window.location.reload();
  };

  useEffect(() => {
    const lang = localStorage.getItem("language");
    if (lang) {
      I18n.setLanguage(lang);
      setLanguage(lang);
    }
  }, []);

  return (
    <>
      {showBack ? (
        <IconButton onClick={handleBack} disabled={false} size="small">
          <NavigateBeforeIcon sx={{ color: "gray" }} />
        </IconButton>
      ) : null}
      <Typography
        variant={!mobileDevice ? "h6" : "body1"}
        component="div"
        sx={{ flexGrow: 1, color: "gray" }}
      >
        {getHeaderTitle()}
      </Typography>
      <FormGroup
        row
        sx={{
          alignItems: "center",
          ml: 2,
          display: "flex",
          flexWrap: "nowrap",
        }}
      >
        <LightModeIcon
          sx={{ color: "gray", fontSize: mobileDevice ? "medium" : "large" }}
        />
        <Switch size="small" onChange={toggleDarkMode} />
        <DarkModeIcon
          sx={{ color: "gray", fontSize: mobileDevice ? "medium" : "large" }}
        />
      </FormGroup>
      <IconButton onClick={handleMenuOpen}>
        <MoreVertIcon sx={{ color: "gray" }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleLanguageToggle}>
          {language === "en" ? (
            <>
              <LanguageIcon sx={{ mr: 1 }} />
              Switch to Italian
            </>
          ) : (
            <>
              <LanguageIcon sx={{ mr: 1 }} />
              Switch to English
            </>
          )}
        </MenuItem>
        <MenuItem href="#logout" onClick={signout}>
          {I18n.get("sign_out")}
        </MenuItem>
      </Menu>
    </>
  );
}

export default Header;

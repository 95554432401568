import React, { useState, useEffect, useReducer } from "react";
import { API } from "aws-amplify";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tooltip from "@mui/material/Tooltip";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import HistoryIcon from "@mui/icons-material/History";
import AddDataPopup from "../components/popups/AddDataPopup";
import ModifyDataPopup from "../components/popups/ModifyDataPopup";
import DeleteDataPopup from "../components/popups/DeleteDataPopup";
import { APIStatusDialog } from "./APIStatusDialog";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Switch from "@mui/material/Switch";
import { I18n } from "aws-amplify";
import TextField from "@mui/material/TextField";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
} from "@mui/material";

//The main data reducer of the data grid
const dataReducer = (state, action) => {
  switch (action.type) {
    case "DATA_RECEIVED":
      return { data: action.payload, fetched: true };
    default:
      return state;
  }
};

const detailsReducer = (state, action) => {
  switch (action.type) {
    case "DATA_RECEIVED":
      return { data: action.payload, fetched: true };
    default:
      return state;
  }
};

function Tags() {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(dataReducer, {
    data: [],
    fetched: false,
  });
  const [detailsState, dispatchDetails] = useReducer(detailsReducer, {
    data: [],
    fetched: false,
  });
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const theme = useTheme();
  const mobileDevice = !useMediaQuery(theme.breakpoints.up("md"));
  const [order, setOrder] = useState("asc");
  const [isModifyPopupOpen, setIsModifyPopupOpen] = useState(false);
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [tagIDValue, setTagIDValue] = useState(I18n.get("All"));
  const [serialNumberValue, setSerialNumberValue] = useState(I18n.get("All"));
  const [organizationValue, setOrganizationValue] = useState(I18n.get("All"));
  const [apiStatus, setApiStatus] = useState({
    open: false,
    status: "",
    message: "",
  });
  const [clientGeolocation, setClientGeolocation] = useState({
    lat: null,
    lon: null,
  });

  const headerCells = [
    {
      id: "tag_id",
      label: "Tag ID",
      align: "left",
      style: { width: mobileDevice ? "10%" : "10%" },
      show: true,
    },
    {
      id: "serial_number",
      label: I18n.get("serial_number"),
      style: { width: "10%" },
      align: "left",
      show: true,
    },
    {
      id: "organization_name",
      label: I18n.get("organization"),
      style: { width: "10%" },
      align: "left",
      show: true,
    },
    {
      id: "customer_serial_number",
      label: I18n.get("customer_serial_number"),
      style: { width: "10%" },
      align: "left",
      show: !mobileDevice,
    },
    {
      id: "non_epta",
      label: I18n.get("epta_equipment"),
      style: { width: "5%" },
      align: "center",
      show: !mobileDevice,
    },
    {
      id: "linked_at",
      label: I18n.get("linked_at"),
      align: "center",
      style: { width: "15%" },
      show: !mobileDevice,
    },
    {
      id: "created_by_user",
      label: I18n.get("created_by_user"),
      align: "left",
      style: { width: "20%" },
      show: !mobileDevice,
    },
    {
      id: "actions",
      label: I18n.get("actions"),
      style: { width: "30%" },
      align: "center",
      show: true,
    },
  ];

  const fetchData = async () => {
    const response = await API.get("nfctaglinks", "/getNFCTagLinks", {
      queryStringParameters: {
        tag_id: tagIDValue === I18n.get("All") ? "" : tagIDValue,
        serial: serialNumberValue === I18n.get("All") ? "" : serialNumberValue,
        organization: organizationValue === I18n.get("All") ? "" : organizationValue,
        page: page,
        page_size: rowsPerPage,
      },
    });
    const parsedResponse = JSON.parse(response);
    parsedResponse.forEach((item) => {
      // Convert simulation_timestamp to UTC format with precision up to the second
      if (!item.linked_at.includes(".")) {
        item.linked_at = item.linked_at + ".000000";
      }
      const timestamp = new Date(item.linked_at + "UTC");
      const formattedTimestamp = dayjs(timestamp).format("YYYY-MM-DD HH:mm:ss");
      item.linked_at = formattedTimestamp;
    });
    parsedResponse.sort((a, b) => (a.linked_at < b.linked_at ? 1 : -1));
    dispatch({ type: "DATA_RECEIVED", payload: parsedResponse });
  };

  const fetchNonEptaDetails = async () => {
    let data = {};
    const classificationResponse = await API.get(
      "nfctaglinks",
      "/getClassification",
      {}
    );
    const manufacturerResponse = await API.get(
      "nfctaglinks",
      "/getManufacturer",
      {}
    );
    const organizationResponse = await API.get(
      "nfctaglinks",
      "/getOrganization",
      {}
    );

    // assuming the responses are already in JSON format
    let parsedClassification = JSON.parse(classificationResponse);
    let parsedManufacturer = JSON.parse(manufacturerResponse);
    let parsedOrganization = JSON.parse(organizationResponse);
    // filter out disabled items
    parsedClassification = parsedClassification.filter(
      (item) => !item.disabled
    );
    parsedManufacturer = parsedManufacturer.filter((item) => !item.disabled);
    parsedOrganization = parsedOrganization.filter((item) => !item.disabled);
    data.classification = parsedClassification;
    data.manufacturer = parsedManufacturer;
    data.organization = parsedOrganization;
    dispatchDetails({ type: "DATA_RECEIVED", payload: data });
  };

  const handleApiCall = async (operation, data) => {
    let result;
    let message;

    try {
      switch (operation) {
        case "add":
          result = await API.get("nfctaglinks", "/addNFCTagLink", {
            queryStringParameters: {
              tag_id: data.tag_id,
              serial_number: data.serial_number,
              customer_serial_number: data.customer_serial_number,
              created_by_user: data.username,
              non_epta: data.nonEpta,
              manufacturer_id: data.manufacturerID,
              classification_id: data.classificationID,
              organization_id: data.organizationID,
              year: data.year,
              latitude: clientGeolocation.lat,
              longitude: clientGeolocation.lon,
            },
          });

          if (result === "The Tag ID is already paired") {
            message = I18n.get("Tag ID is already paired");
          } else {
            message = I18n.get("Error occurred while adding");
          }
          break;
        case "update":
          result = await API.get("nfctaglinks", "/updateNFCTagLink", {
            queryStringParameters: {
              tag_id: data.tag_id,
              serial_number: data.serial_number,
              customer_serial_number: data.customer_serial_number,
              latitude: clientGeolocation.lat,
              longitude: clientGeolocation.lon,
            },
          });
          message = I18n.get("Error occurred while updating");
          break;
        case "delete":
          result = await API.get("nfctaglinks", "/deleteNFCTagLink", {
            queryStringParameters: {
              tag_id: data.tag_id,
              customer_serial_number: data.customer_serial_number,
              latitude: clientGeolocation.lat,
              longitude: clientGeolocation.lon,
            },
          });
          message = I18n.get("Error occurred while deleting");
          break;
        default:
          throw new Error("Invalid operation");
      }

      let pastOperation;
      switch (operation) {
        case "add":
          pastOperation = "added";
          break;
        case "update":
          pastOperation = "updated";
          break;
        case "delete":
          pastOperation = "deleted";
          break;
        default:
          throw new Error("Invalid operation");
      }

      if (result === 1) {
        setApiStatus({
          open: true,
          status: "success",
          message: `${I18n.get("Successfully " + pastOperation)}`,
        });
      } else {
        setApiStatus({
          open: true,
          status: "error",
          message: message,
        });
      }
    } catch (error) {
      setApiStatus({
        open: true,
        status: "error",
        message: message,
      });
    }

    setTimeout(() => {
      setApiStatus((prevState) => ({ ...prevState, open: false }));
    }, 2000);

    return result;
  };

  function successGeolocation(position) {
    setClientGeolocation({
      lat: position.coords.latitude,
      lon: position.coords.longitude,
    });
  }

  function errorGeolocation() {
    console.log("Geolocation is not active or not supported");
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        successGeolocation,
        errorGeolocation
      );
    } else {
      console.log("Geolocation not supported");
    }
  }, []);

  useEffect(() => {
    // First API call on the page
    fetchData();
    fetchNonEptaDetails();
  }, [page, rowsPerPage]);

  const handleOpenModifyPopup = (details) => {
    setSelectedRowData(details);
    setIsModifyPopupOpen(true);
  };

  const handleOpenDeletePopup = (details) => {
    setSelectedRowData(details);
    setIsDeletePopupOpen(true);
  };

  // Function to handle the changes in the TextField
  const handleTagIDChange = (event) => {
    setTagIDValue(event.target.value);
  };

  const handleSerialChange = (event) => {
    setSerialNumberValue(event.target.value);
  };

  const handleOrganizationChange = (event) => {
    setOrganizationValue(event.target.value);
  };


  const handleSearch = () => {
    fetchData();
  };

  const handleAddSave = async (newData) => {
    setApiStatus({
      open: true,
      status: "pending",
      message: I18n.get("Saving..."),
    });
    await handleApiCall("add", newData);
    fetchData();
  };

  const handleModifySave = async (modifiedData) => {
    setApiStatus({
      open: true,
      status: "pending",
      message: I18n.get("Updating..."),
    });
    await handleApiCall("update", modifiedData);
    fetchData();
  };

  const handleDeleteSave = async (rowData) => {
    setApiStatus({
      open: true,
      status: "pending",
      message: I18n.get("Deleting..."),
    });
    await handleApiCall("delete", rowData);
    fetchData();
  };

  const handleGoToEvents = (tagId) => {
    navigate(`/admin/events/${tagId}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const reorder = () => {
    let newData = [...state.data];
    if (order === "asc") {
      newData.sort((a, b) => (a.linked_at > b.linked_at ? 1 : -1));
      setOrder("desc");
    } else {
      newData.sort((a, b) => (a.linked_at < b.linked_at ? 1 : -1));
      setOrder("asc");
    }

    // Update the state with the new sorted data
    dispatch({ type: "DATA_RECEIVED", payload: newData });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const applyFiltering = function (value) {
    if (searchText) {
      const searchTextLower = searchText.toLowerCase();
      return Object.values(value).some((val) =>
        String(val).toLowerCase().includes(searchTextLower)
      );
    }

    return true;
  };

  const searchChanged = function (event) {
    setSearchText(event.target.value);
  };

  return (
    <>
      <Paper elevation={0} sx={{ padding: "0.5rem" }}>
        {!state.fetched || !detailsState.fetched ? (
          <Stack alignItems="center" sx={{ height: "100%" }}>
            <Skeleton
              variant="rounded"
              height={40}
              width="100%"
              sx={{ margin: "2px", marginBottom: "5px" }}
            />
            <Divider sx={{ width: "100%" }} />
            <Skeleton
              variant="rounded"
              height={30}
              width="100%"
              sx={{ margin: "2px", marginTop: "5px" }}
            />
            <Skeleton
              variant="rounded"
              height={30}
              width="100%"
              sx={{ margin: "2px" }}
            />
            <Skeleton
              variant="rounded"
              height={30}
              width="100%"
              sx={{ margin: "2px" }}
            />
          </Stack>
        ) : (
          <Box sx={{ height: "100%", width: "100%" }}>
            {mobileDevice ? (
              <Toolbar
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  minHeight: "1rem",
                }}
              >
                <IconButton onClick={() => setIsAddPopupOpen(true)}>
                  <Button variant="contained">
                    <AddIcon color="textPrimary" />
                    {I18n.get("new_pairing")}
                  </Button>
                </IconButton>
                <Stack spacing={2} direction={mobileDevice ? "column" : "row"}>
                  <TextField
                    label={I18n.get("tag_id")}
                    value={tagIDValue}
                    onChange={handleTagIDChange}
                  />
                  <TextField
                    label={I18n.get("serial_number")}
                    value={serialNumberValue}
                    onChange={handleSerialChange}
                  />
                  <TextField
                    label={I18n.get("organization")}
                    value={organizationValue}
                    onChange={handleOrganizationChange}
                  />
                  <IconButton>
                    <SearchIcon onClick={handleSearch} />
                  </IconButton>
                </Stack>
              </Toolbar>
            ) : (
              <Toolbar
                sx={{
                  justifyContent: "space-between",
                  minHeight: "1rem",
                }}
              >
                <Button
                  sx={{ mt: -2 }}
                  variant="contained"
                  onClick={() => setIsAddPopupOpen(true)}
                >
                  <AddIcon color="textPrimary" />
                  {I18n.get("new_pairing")}
                </Button>

                <Stack
                  spacing={2}
                  sx={{ pt: 2, pb: 2 }}
                  direction={mobileDevice ? "column" : "row"}
                >
                  <TextField
                    label={I18n.get("tag_id")}
                    value={tagIDValue}
                    onChange={handleTagIDChange}
                  />
                  <TextField
                    label={I18n.get("serial_number")}
                    value={serialNumberValue}
                    onChange={handleSerialChange}
                  />
                  <TextField
                    label={I18n.get("organization")}
                    value={organizationValue}
                    onChange={handleOrganizationChange}
                  />
                  <IconButton>
                    <SearchIcon onClick={handleSearch} />
                  </IconButton>
                </Stack>
              </Toolbar>
            )}
            <TableContainer>
              <Table size="small" sx={{ height: "100%", width: "100%" }}>
                <TableHead>
                  <TableRow key="header">
                    {headerCells.map(
                      (column) =>
                        column.show && (
                          <TableCell
                            key={column.id}
                            style={column.style}
                            align={column.align}
                          >
                            {column.id === "linked_at" ? (
                              <div>
                                <strong>{column.label}</strong>
                                <IconButton onClick={reorder}>
                                  <Tooltip
                                    arrow
                                    title={I18n.get("order_items")}
                                  >
                                    <div>
                                      <ArrowDownwardIcon
                                        style={{
                                          fontSize: 20,
                                          color: "grey",
                                          display:
                                            order === "asc" ? "inline" : "none",
                                        }}
                                      />
                                      <ArrowUpwardIcon
                                        style={{
                                          fontSize: 20,
                                          color: "grey",
                                          display:
                                            order === "asc" ? "none" : "inline",
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                </IconButton>
                              </div>
                            ) : (
                              <strong>{column.label}</strong>
                            )}
                          </TableCell>
                        )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.data
                    .filter(applyFiltering)
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow key={index}>
                        {headerCells.map((column) => {
                          if (column.show) {
                            if (column.id === "actions") {
                              return (
                                <TableCell
                                  sx={{
                                    textAlign: "center",
                                  }}
                                  key={column.id}
                                >
                                  <Stack
                                    direction="row"
                                    justifyContent="center"
                                  >
                                    <IconButton
                                      onClick={() => handleOpenModifyPopup(row)}
                                    >
                                      <Tooltip arrow title={I18n.get("edit")}>
                                        <EditIcon color="primary" />
                                      </Tooltip>
                                    </IconButton>
                                    <IconButton
                                      onClick={() => handleOpenDeletePopup(row)}
                                    >
                                      <Tooltip arrow title={I18n.get("delete")}>
                                        <DeleteIcon color="error" />
                                      </Tooltip>
                                    </IconButton>
                                    <IconButton
                                      onClick={() =>
                                        handleGoToEvents(row.tag_id)
                                      }
                                    >
                                      <Tooltip arrow title={I18n.get("events")}>
                                        <HistoryIcon color="action" />
                                      </Tooltip>
                                    </IconButton>
                                  </Stack>
                                </TableCell>
                              );
                            } else if (column.id === "non_epta") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <Switch checked={!row[column.id]} disabled />
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {row[column.id]}
                                </TableCell>
                              );
                            }
                          }
                          return null;
                        })}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={state.data.length > 0 ? state.data[0].row_count : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={I18n.get("rows_per_page")}
              labelDisplayedRows={({ from, to, count }) => {
                return "" + from + "-" + to + ` ${I18n.get("of")} ` + count;
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        )}
        {isAddPopupOpen && (
          <AddDataPopup
            open={isAddPopupOpen}
            onClose={() => setIsAddPopupOpen(false)}
            onSave={handleAddSave}
            nonEptaDetails={detailsState}
          />
        )}
        {isDeletePopupOpen ? (
          <DeleteDataPopup
            open={isDeletePopupOpen}
            onClose={() => setIsDeletePopupOpen(false)}
            rowData={selectedRowData}
            onSave={handleDeleteSave}
            onCancel={() => setIsDeletePopupOpen(false)}
          />
        ) : null}
        {isModifyPopupOpen ? (
          <ModifyDataPopup
            open={isModifyPopupOpen}
            onClose={() => setIsModifyPopupOpen(false)}
            rowData={selectedRowData}
            onSave={handleModifySave}
            nonEptaDetails={detailsState}
          />
        ) : null}
        <APIStatusDialog
          open={apiStatus.open}
          status={apiStatus.status}
          message={apiStatus.message}
        />
      </Paper>
    </>
  );
}

export default Tags;

import SellIcon from "@mui/icons-material/Sell";
import GroupIcon from "@mui/icons-material/Group";
import Tooltip from "@mui/material/Tooltip";
import HistoryIcon from "@mui/icons-material/History";
import { I18n } from "aws-amplify";
import { translations } from "./translations/translations.js";

I18n.putVocabularies(translations);
const lang = localStorage.getItem("language");
if (lang) {
  I18n.setLanguage(lang);
}
else{
  I18n.setLanguage('en'); // use en as default language 
}

const dashboardRoutes = [
  {
    path: "/tags",
    name: I18n.get("tags"),
    mui_icon: (
      <Tooltip arrow title="Tags">
        <SellIcon sx={{ color: "white" }} />
      </Tooltip>
    ),
    layout: "/admin",
  },
  {
    path: "/users",
    name: I18n.get("users"),
    mui_icon: (
      <Tooltip arrow title="Users">
        <GroupIcon sx={{ color: "white" }} />
      </Tooltip>
    ),
    layout: "/admin",
  },
  {
    path: "/events",
    name: I18n.get("events"),
    mui_icon: (
      <Tooltip arrow title="History">
        <HistoryIcon sx={{ color: "white" }} />
      </Tooltip>
    ),
    layout: "/admin",
  },
];

export default dashboardRoutes;

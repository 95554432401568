import { Flex, Link, useAuthenticator, useTheme } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";
export function SignInFooter() {
  const { toResetPassword } = useAuthenticator();
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center" padding={`0 0 ${tokens.space.medium}`}>
      <Link onClick={toResetPassword}>{I18n.get("forgot_pass")}</Link>
    </Flex>
  );
}

import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import { I18n } from "aws-amplify";

export const APIStatusDialog = ({ open, status, message }) => {
  const getIcon = () => {
    switch (status) {
      case "pending":
        return <HourglassEmptyIcon color="primary" />;
      case "success":
        return <CheckCircleOutlineIcon color="success" />;
      case "error":
        return <ErrorOutlineIcon color="error" />;
      default:
        return null;
    }
  };

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal }}
      open={open}
    >
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{I18n.get("op_status")}</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center">
            {getIcon()}
            <DialogContentText id="alert-dialog-description" sx={{ ml: 1 }}>
              {message}
            </DialogContentText>
          </Box>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
};

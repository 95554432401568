import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { I18n } from "aws-amplify";
function DeleteDataPopup({ open, onClose, rowData, onSave, onCancel }) {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleSave = () => {
    setIsDeleting(true);
    onSave(rowData);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>{I18n.get("delete_title")}</DialogTitle>
      <DialogContent>
        <p>{I18n.get("delete_msg")}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={isDeleting}>
          {I18n.get("cancel")}
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="error"
          disabled={isDeleting}
        >
          {I18n.get("delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDataPopup;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://vtfsd24se6.execute-api.eu-central-1.amazonaws.com/master",
            "region": "eu-central-1"
        },
        {
            "name": "nfctaglinks",
            "endpoint": "https://697uscpvyc.execute-api.eu-central-1.amazonaws.com/master",
            "region": "eu-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:bfec9477-6901-44d0-8daf-034e1c74310c",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_jDv8wKZtP",
    "aws_user_pools_web_client_id": "22sgsca5qcm5o32pa4d0302djh",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;

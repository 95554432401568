import { useState, useEffect, useRef } from "react";
import { Auth } from "aws-amplify";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { Select, MenuItem } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { I18n } from "aws-amplify";
import { BarcodeScanner } from "../scanner/BarcodeScanner";

function ModifyDataPopup(props) {
  const [data, setData] = useState({
    organizationID: props.rowData.organization_id,
    tag_id: props.rowData.tag_id,
    serial_number: props.rowData.serial_number,
    customer_serial_number: props.rowData.customer_serial_number,
  });
  const customerSerialRef = useRef();
  const [serNumFocused, setSerNumFocused] = useState(false);
  const [serialRule, setSerialRule] = useState(null);
  const [serialPlaceholder, setSerialPlaceholder] = useState("XXXXXXX");
  const [serialNumberError, setSerialNumberError] = useState(null);
  const [barcodeScanner, setBarcodeScanner] = useState({
    visible: false,
    callback: null,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkSerial = (newSerial) => {
    if (serialRule) {
      if (!serialRule.test(newSerial)) {
        setSerialNumberError(I18n.get("ser_format_err"));
      } else {
        setSerialNumberError(null);
      }
    }
  };

  const createSerialPlaceholder = (rule) => {
    if (rule && rule.toString().includes("\\d")) {
      rule = rule.toString();
      const idx = rule.indexOf("\\d");
      const sub = rule.substring(2, idx);
      const idxOfMin = rule.indexOf("{") + 1;
      const idxOfMax = rule.indexOf(",");
      const minDigits = Number(rule.substring(idxOfMin, idxOfMax));
      const placeholder = sub + "X".repeat(minDigits);
      setSerialPlaceholder(placeholder);
    } else {
      setSerialPlaceholder("XXXXXXX");
    }
  };

  const handleSave = async () => {
    props.onSave(data);
    props.onClose();
  };

  const showScanner = (targetName) => {
    const onScanCallback = (value) => {
      const event = {
        target: {
          name: targetName,
          value: value,
        },
      };
      handleInputChange(event);
      setTimeout(() => {
        customerSerialRef.current.focus();
      }, 100);
      setBarcodeScanner({ visible: false });
    };
    setBarcodeScanner({ visible: true, callback: onScanCallback });
  };

  const correctSerNum =
    data["serial_number"] !== undefined &&
    data["serial_number"] !== null &&
    data["serial_number"].length > 0;

  useEffect(() => {
    const setUser = async () => {
      const { username } = await Auth.currentAuthenticatedUser();
      setData((prevState) => ({
        ...prevState,
        username: username,
      }));
    };
    setUser();
  }, []);

  useEffect(() => {
    if (props.nonEptaDetails.data && props.nonEptaDetails.data.organization) {
      const selectedOrg = props.nonEptaDetails.data.organization.find(
        (org) => org?.id?.toString() === data?.organizationID?.toString()
      );
      let pattern, flags;
      if (selectedOrg && selectedOrg.rule) {
        pattern = selectedOrg.rule.slice(1, selectedOrg.rule.lastIndexOf("/"));
        flags = selectedOrg.rule.slice(selectedOrg.rule.lastIndexOf("/") + 1);
      } else {
        pattern = ".*";
        flags = "m";
      }
      setSerialRule(new RegExp(pattern, flags));
    }
  }, [props.nonEptaDetails.data, data.organizationID]);

  useEffect(() => {
    createSerialPlaceholder(serialRule);
  }, [serialRule]);

  useEffect(() => {
    checkSerial(data.serial_number);
  }, [data.serial_number, data.tag_id, serialRule]);

  return props.open ? (
    <Dialog fullWidth maxWidth="sm" open={props.open} onClose={props.onClose}>
      <DialogTitle>
        {I18n.get("modify_tag")}
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form>
          {barcodeScanner.visible && (
            <BarcodeScanner onScan={barcodeScanner.callback} />
          )}
          <FormControl fullWidth key={"organizationID"} margin="dense">
            <InputLabel>{I18n.get("organization")}</InputLabel>
            <Select
              value={data["organizationID"] || ""}
              name={"organizationID"}
              label={I18n.get("organization")}
              disabled
            >
              <MenuItem value="">{I18n.get("none")}</MenuItem>
              {props.nonEptaDetails.data &&
                props.nonEptaDetails.data.organization &&
                props.nonEptaDetails.data.organization.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            name="tag_id"
            label={I18n.get("tag_id")}
            placeholder="BBXXXXXX"
            type="text"
            value={data.tag_id}
            disabled
            fullWidth
          />
          <TextField
            margin="dense"
            name="serial_number"
            label={I18n.get("serial_number")}
            placeholder={serialPlaceholder}
            type="text"
            value={data.serial_number}
            onChange={handleInputChange}
            fullWidth
            error={
              serNumFocused && (!correctSerNum || serialNumberError || false)
            }
            onFocus={() => setSerNumFocused(true)}
            onBlur={() => setSerNumFocused(false)}
            helperText={
              !correctSerNum
                ? I18n.get("mandatory_field")
                : serialNumberError
                ? serialNumberError
                : ""
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <QrCodeScannerIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => showScanner("serial_number")}
                  />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="dense"
            name="customer_serial_number"
            label={I18n.get("customer_serial_number")}
            inputRef={customerSerialRef}
            type="text"
            value={data.customer_serial_number}
            onChange={handleInputChange}
            fullWidth
          />
          <Button
            sx={{ mt: 2 }}
            onClick={handleSave}
            variant="contained"
            disabled={!correctSerNum || serialNumberError || false}
          >
            {I18n.get("save")}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  ) : null;
}

export default ModifyDataPopup;

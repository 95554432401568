import { useState } from "react";
import { useZxing } from "react-zxing";

export const BarcodeScanner = ({ onScan }) => {
    const [result, setResult] = useState("");
    const { ref } = useZxing({
        onResult(result) {
            setResult(result.getText());
            onScan(result.getText())
        }
    });

    return (
        <div style={{ width: "100%" }}>
            <video style={{ width: "100%" }} ref={ref} />
        </div>
    );
};
import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useState } from "react";
import AdminLayoutWithDrawer from "./layouts/AdminWithDrawer.js";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Paper from "@mui/material/Paper";
import { I18n } from 'aws-amplify';
import { translations } from './translations/translations.js';
import Tags from "./views/Tags.js";
import Users from "./views/Users.js";
import Events from "./views/Events.js";

import { useAuthenticator } from "@aws-amplify/ui-react";
import { Login } from "./views/Login";
import { Auth } from "@aws-amplify/auth";

I18n.putVocabularies(translations);

const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.error("Error signing out: ", error);
  }
};

function errorFallback({ error, resetErrorBoundary }) {
  return (
    <Box component={Paper}>
      <Alert severity="error">
        <AlertTitle>Something went wrong:</AlertTitle>
        {error.message}
      </Alert>
    </Box>
  );
}

export default function App() {
  const lightTheme = createTheme({
    palette: {
      mode: "light",
    },
  });

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const [currentMode, setCurrentMode] = useState("light");
  const [currentTheme, setCurrentTheme] = useState(lightTheme);
  const toggleDarkMode = function () {
    if (currentMode === "dark") {
      setCurrentMode("light");
      setCurrentTheme(lightTheme);
    } else {
      setCurrentMode("dark");
      setCurrentTheme(darkTheme);
    }
  };
  const { user } = useAuthenticator();
  if (user) {
    return (
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        <ErrorBoundary FallbackComponent={errorFallback}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Navigate to="admin/tags" />} />
              <Route
                path="admin"
                element={
                  <AdminLayoutWithDrawer toggleDarkMode={toggleDarkMode} signOut={signOut} />
                }
              >
                <Route path="tags" element={<Tags />} />
                <Route path="users" element={<Users />} />
                <Route path="events" element={<Events />} />
                <Route path="events/:tagID" element={<Events />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ErrorBoundary>
      </ThemeProvider>
    );
  }
  return <Login />;
}

import React from "react";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import LaunchIcon from "@mui/icons-material/Launch";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const StyledFooter = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "white" : "black",
}));

function Footer() {
  const theme = useTheme();
  const mobileDevice = !useMediaQuery(theme.breakpoints.up("md"));
  return (
    <StyledFooter>
      <footer
        className="px-0 px-lg-3"
        style={{
          height: "60px",
          opacity: 0.8,
          display: "flex",
          alignItems: "center",
        }}
      >
        {!mobileDevice ? (
          <Stack direction="row" spacing={1}>
            <Chip
              icon={<LaunchIcon sx={{ width: "1rem" }} />}
              clickable
              component="a"
              label="Real-Time"
              href="https://g-5645b0bce7.grafana-workspace.eu-central-1.amazonaws.com/d/m14deQq7z/rds-map?orgId=1"
              target="_blank"
            />
            <Chip
              icon={<LaunchIcon sx={{ width: "1rem" }} />}
              clickable
              component="a"
              label="Analytics"
              href="https://eu-central-1.quicksight.aws.amazon.com/sn/dashboards/54f4cae3-7ed9-4d44-bbe6-9281c5bad615/sheets/54f4cae3-7ed9-4d44-bbe6-9281c5bad615_1d3f22f8-9c38-4644-9a8e-c4891b732dcc"
              target="_blank"
            />
            <Chip
              icon={<LaunchIcon sx={{ width: "1rem" }} />}
              clickable
              component="a"
              label="Company"
              href="https://www.eptarefrigeration.com/en"
              target="_blank"
            />
            <Chip
              icon={<LaunchIcon sx={{ width: "1rem" }} />}
              clickable
              component="a"
              label="Switchon"
              href="https://www.switchon.epta-service.com/"
              target="_blank"
            />
            <Chip
              icon={<LaunchIcon sx={{ width: "1rem" }} />}
              clickable
              component="a"
              label="Omnichannel"
              href="https://www.epta-digitalhub.com/"
              target="_blank"
            />
          </Stack>
        ) : (
          <Stack direction="row" spacing={1}>
            <Stack spacing={1}>
              <Chip
                icon={<LaunchIcon sx={{ width: "1rem" }} />}
                clickable
                component="a"
                label="Real-Time"
                href="https://g-5645b0bce7.grafana-workspace.eu-central-1.amazonaws.com/d/m14deQq7z/rds-map?orgId=1"
                target="_blank"
                size="small"
              />
              <Chip
                icon={<LaunchIcon sx={{ width: "1rem" }} />}
                clickable
                component="a"
                label="Analytics"
                href="https://eu-central-1.quicksight.aws.amazon.com/sn/dashboards/54f4cae3-7ed9-4d44-bbe6-9281c5bad615/sheets/54f4cae3-7ed9-4d44-bbe6-9281c5bad615_1d3f22f8-9c38-4644-9a8e-c4891b732dcc"
                target="_blank"
                size="small"
              />
            </Stack>
            <Stack spacing={1}>
              <Chip
                icon={<LaunchIcon sx={{ width: "1rem" }} />}
                clickable
                component="a"
                label="Company"
                href="https://www.eptarefrigeration.com/en"
                target="_blank"
                size="small"
              />
              <Chip
                icon={<LaunchIcon sx={{ width: "1rem" }} />}
                clickable
                component="a"
                label="Switchon"
                href="https://www.switchon.epta-service.com/"
                target="_blank"
                size="small"
              />
            </Stack>
            <Stack spacing={1}>
              <Chip
                icon={<LaunchIcon sx={{ width: "1rem" }} />}
                clickable
                component="a"
                label="Omnichannel"
                href="https://www.epta-digitalhub.com/"
                target="_blank"
                size="small"
              />
            </Stack>
          </Stack>
        )}
      </footer>
    </StyledFooter>
  );
}

export default Footer;

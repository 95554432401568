export const translations = {
  en: {
    tag_id: "Tag ID",
    serial_number: "Serial Number",
    customer_serial_number: "Customer Serial Number",
    epta_equipment: "EPTA equipment",
    linked_at: "Linked at",
    created_by_user: "Created by",
    actions: "Actions",
    order_items: "Order items",
    event: "Event",
    event_timestamp: "Event Timestamp",
    start_date: "Start Date",
    end_date: "End Date",
    export: "Export",
    edit: "Edit",
    save: "Save",
    delete: "Delete",
    delete_title: "Delete Data",
    delete_msg: "Are you sure you want to delete this data ?",
    cancel: "Cancel",
    events: "Events",
    search_msg: "Search a Data..",
    search_user_msg: "Search a User..",
    tags: "Tags",
    users: "Users",
    user_management: "User Management",
    username: "Username",
    created_date: "created Date",
    last_modified: "Last Modified",
    status: "Status",
    enabled: "Enabled",
    admin: "Admin",
    no_name: "No Name",
    sign_out: "Sign Out",
    start_date_error_msg: "Start date should be before end date",
    end_date_error_msg: "End date should be after start date",
    pass_num_char_error: "Password must be at least 8 characters.",
    pass_num_error: "Password must contain at least one number.",
    pass_low_char_error: "Password must contain at least one lowercase letter.",
    pass_up_char_error: "Password must contain at least one uppercase letter.",
    pass_spec_char_error:
      "Password must contain at least one special character",
    email_address: "Email Address",
    temp_pass: "Temporary Password",
    email_error: "Invalid email format",
    sign_up_user: "Sign Up a User",
    sign_up: "Sign Up",
    sign_in: "Sign In",
    forgot_pass: "Forgot your password?",
    mandatory_field: "Mandatory field",
    epta: "EPTA",
    not_epta: "Not EPTA",
    manufacturer: "Manufacturer",
    manufacturerID: "manufacturerID",
    classification: "Classification",
    classificationID: "classificationID",
    none: "None",
    year: "Year",
    add_tag: "Add a Tag",
    modify_tag: " Modify the Tag",
    nfc_management: "NFC Management",
    new_pairing: "New Pairing",
    event_type: "Event",
    rows_per_page: "Rows per page",
    organization: "Organization",
    ser_format_err: "Invalid serial number format",
    tag_format_err: "Invalid Tag ID format",
    op_status: "Operation Status",
    organization: "Organization"
  },
  it: {
    tag_id: "ID Tag",
    serial_number: "Seriale",
    customer_serial_number: "Seriale del Cliente",
    epta_equipment: "EPTA",
    linked_at: "Collegato a",
    created_by_user: "Creato da",
    actions: "Azioni",
    order_items: "Ordina Articoli",
    event: "Evento",
    event_timestamp: "Orario dell'Evento",
    start_date: "Data d'Inizio",
    end_date: "Data di Fine",
    export: "Esporta",
    edit: "Modifica",
    save: "Salva",
    delete: "Elimina",
    delete_title: "Elimina Dati",
    delete_msg: "Sei sicuro di voler eliminare questa associazione?",
    cancel: "Annulla",
    events: "Eventi",
    search_msg: "Cerca..",
    search_user_msg: "Cerca un Utente..",
    tags: "Tag",
    users: "Utenti",
    user_management: "Gestione Utenti",
    username: "Nome Utente",
    created_date: "Data di Creazione",
    last_modified: "Ultima Modifica",
    status: "Stato",
    enabled: "Abilitato",
    admin: "Amministratore",
    no_name: "Nessun Nome",
    sign_in: "Accedi",
    forgot_pass: "Hai dimenticato la tua password?",
    "Sign in": "Accedi",
    Username: "Nome Utente",
    "Enter your Username": "Inserisci la tua Email",
    "Enter your username": "Inserisci il tuo nome utente",
    "Enter your Password": "Inserisci la tua Password",
    "Send code": "Invia il codice",
    "Back to Sign In": "Torna indietro",
    "Please fill out this field.": "Per favore, riempi questo campo.",
    "Signing in": "Accesso in corso..",
    sign_out: "Esci",
    start_date_error_msg:
      "La data di inizio dovrebbe essere prima della data di fine",
    end_date_error_msg:
      "La data di fine dovrebbe essere dopo la data di inizio",
    pass_num_char_error: "La password deve essere di almeno 8 caratteri.",
    pass_num_error: "La password deve contenere almeno un numero.",
    pass_low_char_error:
      "La password deve contenere almeno una lettera minuscola.",
    pass_up_char_error:
      "La password deve contenere almeno una lettera maiuscola.",
    pass_spec_char_error:
      "La password deve contenere almeno un carattere speciale",
    email_address: "Indirizzo Email",
    temp_pass: "Password Temporanea",
    email_error: "Formato email non valido",
    sign_up_user: "Registra un Utente",
    sign_up: "Registrati",
    mandatory_field: "Campo obbligatorio",
    epta: "EPTA",
    not_epta: "Non EPTA",
    manufacturer: "Produttore",
    manufacturerID: "ID Produttore",
    classification: "Classificazione",
    classificationID: "ID Classificazione",
    none: "Nessuno",
    year: "Anno",
    add_tag: "Aggiungi un Tag",
    modify_tag: "Modifica il Tag",
    nfc_management: "Gestione NFC",
    new_pairing: "Aggiungi Tag",
    event_type: "Evento",
    rows_per_page: "Righe per pagina",
    organization: "Organizzazione",
    ser_format_err: "Formato del seriale non è valido",
    tag_format_err: "Formato del Tag ID non è valido",
    "Adding...": "Aggiunta in corso...",
    "Successfully added": "Aggiunta effettuata con successo",
    "Successfully updated": "Aggiornamento effettuato con successo",
    "Successfully deleted": "Eliminato con successo",
    "Successfully saved": "Salvato con successo",
    "Error occurred while adding":
      "Si è verificato un errore durante l'aggiunta",
    "Error occurred while deleting":
      "Si è verificato un errore durante l'eliminazione",
    "Error occurred while updating":
      "Si è verificato un errore durante l'aggiornamento",
    "Deleting...": "Eliminazione in corso...",
    "Saving...": "Salvataggio in corso...",
    "Updating...": "Aggiornamento in corso...",
    "Tag ID or Serial number is already paired":
      "Tag ID o Seriale è già associato",
    All: "Tutti",
    op_status: "Stato Operazione",
    "No Data !": "Nessun Dato !",
    "There is no data to display at this moment.":
      "In questo momento non c'è nessun dato da mostrare.",
      of: "di",
      organization: "Organizzazione"
  },
};

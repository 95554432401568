import * as React from "react";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

export default function SidebarDrawerList({ routes, open }) {
  return (
    <Stack
      sx={{ height: "100%" }}
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <List sx={{ width: "100%" }}>
        {routes.map((prop) => {
          if (!prop.redirect)
            return (
              <ListItem
                key={prop.name}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  component={Link}
                  to={{
                    pathname: prop.layout + prop.path,
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {prop.mui_icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={prop.name}
                    sx={{ color: "#fff", opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            );
          return null;
        })}
      </List>
      {open ? (
        <Box
          sx={{
            width: "100%",
            pb: 3,
            pr: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            alt=""
            width="100px"
            src={require("../../assets/img/header/LOGO_EptaWithoutService.png")}
          />
        </Box>
      ) : null}
    </Stack>
  );
}

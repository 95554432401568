import React, { useState, useEffect, useReducer } from "react";
import { API, Auth } from "aws-amplify";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tooltip from "@mui/material/Tooltip";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AddIcon from "@mui/icons-material/Add";
import SignupDialog from "../components/popups/SignupDialog";
import dayjs from "dayjs";
import Switch from "@mui/material/Switch";
import { I18n } from "aws-amplify";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import AdminAlert from "../components/cards/AdminAlert.js";

//The main data reducer of the data grid
const dataReducer = (state, action) => {
  switch (action.type) {
    case "DATA_RECEIVED":
      return { data: action.payload, fetched: true };
    default:
      return state;
  }
};

export default function Users() {
  const [error, setError] = useState(null);
  const [state, dispatch] = useReducer(dataReducer, {
    data: [],
    fetched: false,
  });
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const theme = useTheme();
  const mobileDevice = !useMediaQuery(theme.breakpoints.up("md"));
  const [order, setOrder] = useState("asc");
  const [isModifyPopupOpen, setIsModifyPopupOpen] = useState(false);
  const [signupDialogOpen, setSignupDialogOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [apiStatus, setApiStatus] = useState({
    open: false,
    status: "",
    message: "",
  });

  const headerCells = [
    {
      id: "Username",
      label: I18n.get('username'),
      align: "left",
      style: { width: mobileDevice ? "90" : "30%" },
      show: true,
    },

    {
      id: "UserCreateDate",
      label: I18n.get('created_date'),
      style: { width: "20%" },
      align: "left",
      show: !mobileDevice,
    },
    {
      id: "UserLastModifiedDate",
      label: I18n.get('last_modified'),
      style: { width: "20%" },
      align: "left",
      show: !mobileDevice,
    },
    {
      id: "UserStatus",
      label: I18n.get('status'),
      style: { width: "10%" },
      align: "left",
      show: !mobileDevice,
    },
    {
      id: "Enabled",
      label: I18n.get('enabled'),
      style: { width: "10%" },
      align: "center",
      show: true,
      customCell: (row) => {
        return (
          <Switch
            checked={row["Enabled"]}
            onChange={(e) => handleEnableChange(e, row["Username"])}
            inputProps={{ "aria-label": "controlled" }}
          />
        );
      },
    },
    {
      id: "Role",
      label: I18n.get('admin'),
      align: "center",
      style: { width: "20%" },
      show: true,
      customCell: (row) => {
        return (
          <Switch
            checked={row["Role"] === "Admin"}
            onChange={(e) => handleRoleChange(e, row["Username"])}
            inputProps={{ "aria-label": "controlled" }}
          />
        );
      },
    },
  ];

  const handleEnableChange = (event, user) => {
    if (event.target.checked) {
      enableUser(user);
    } else {
      disableUser(user);
    }
  };

  const handleRoleChange = (event, user) => {
    if (event.target.checked) {
      makeAdmin(user);
    } else {
      revokeAdmin(user);
    }
  };

  const enableUser = async (username) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    };

    const body = {
      username: username,
    };

    try {
      await API.post("AdminQueries", "/enableUser", { headers, body });
      console.log("User enabled successfully");
      fetchData();
    } catch (error) {
      console.error("Error enabling user: ", error);
    }
  };

  const disableUser = async (username) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    };

    const body = {
      username: username,
    };

    try {
      await API.post("AdminQueries", "/disableUser", { headers, body });
      console.log("User disabled successfully");
      fetchData();
    } catch (error) {
      console.error("Error disabling user: ", error);
    }
  };

  const makeAdmin = async (username) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    };

    const body = {
      username: username,
      groupname: "nfcTagAdminPool",
    };

    try {
      await API.post("AdminQueries", "/addUserToGroup", { headers, body });
      fetchData();
    } catch (error) {
      console.error("Error making user admin: ", error);
    }
  };

  const revokeAdmin = async (username) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    };

    const body = {
      username: username,
      groupname: "nfcTagAdminPool",
    };

    try {
      await API.post("AdminQueries", "/removeUserFromGroup", { headers, body });
      fetchData();
    } catch (error) {
      console.error("Error revoking user's admin rights: ", error);
    }
  };

  const fetchData = async () => {
    try {
      let headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
      };

      let headers2 = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
        queryStringParameters: {
          groupname: "nfcTagAdminPool",
        },
      };

      const allUsersResponse = await API.get(
        "AdminQueries",
        "/listUsers",
        headers
      );
      const adminsResponse = await API.get(
        "AdminQueries",
        "/listUsersInGroup",
        headers2
      );

      const allUsers = allUsersResponse.Users.map((user) => {
        let createdtimestamp = new Date(user.UserCreateDate);
        let modifiedtimestamp = new Date(user.UserLastModifiedDate);
        createdtimestamp = dayjs(createdtimestamp).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        modifiedtimestamp = dayjs(modifiedtimestamp).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        return {
          ...user,
          UserCreateDate: createdtimestamp,
          UserLastModifiedDate: modifiedtimestamp,
        };
      });
      let admins = adminsResponse.Users;
      let adminSet = new Set(admins.map((user) => user.Username));
      let mergedList = allUsers.map((user) => {
        let role = adminSet.has(user.Username) ? "Admin" : "User";
        return {
          ...user,
          Role: role,
        };
      });
      dispatch({ type: "DATA_RECEIVED", payload: mergedList });
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const reorder = () => {
    let newData = [...state.data];
    if (order === "asc") {
      newData.sort((a, b) => (a.UserCreateDate > b.UserCreateDate ? 1 : -1));
      setOrder("desc");
    } else {
      newData.sort((a, b) => (a.UserCreateDate < b.UserCreateDate ? 1 : -1));
      setOrder("asc");
    }

    // Update the state with the new sorted data
    dispatch({ type: "DATA_RECEIVED", payload: newData });
  };

  const handleSignup = async (credentials) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    };

    const body = {
      username: credentials.email,
    };

    try {
      await API.post("AdminQueries", "/createUser", { headers, body });
      fetchData();
    } catch (error) {
      console.error("Error making user admin: ", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const applyFiltering = function (value) {
    if (searchText) {
      const searchTextLower = searchText.toLowerCase();
      return Object.values(value).some((val) =>
        String(val).toLowerCase().includes(searchTextLower)
      );
    }

    return true;
  };

  const searchChanged = function (event) {
    setSearchText(event.target.value);
  };

  return (
    <>
      {error && error.response && error.response.status === 403 ? (
        <AdminAlert error={error} />
      ) : (
        <Paper elevation={0} sx={{ padding: "0.5rem" }}>
          {!state.fetched ? (
            <Stack alignItems="center" sx={{ height: "100%" }}>
              <Skeleton
                variant="rounded"
                height={40}
                width="100%"
                sx={{ margin: "2px", marginBottom: "5px" }}
              />
              <Divider sx={{ width: "100%" }} />
              <Skeleton
                variant="rounded"
                height={30}
                width="100%"
                sx={{ margin: "2px", marginTop: "5px" }}
              />
              <Skeleton
                variant="rounded"
                height={30}
                width="100%"
                sx={{ margin: "2px" }}
              />
              <Skeleton
                variant="rounded"
                height={30}
                width="100%"
                sx={{ margin: "2px" }}
              />
            </Stack>
          ) : (
            <Box sx={{ height: "100%", width: "100%" }}>
              <Toolbar
                sx={{
                  justifyContent: "space-between",
                  minHeight: "1rem",
                  ml: -2,
                  pb: 2,
                }}
              >
                <IconButton onClick={() => setSignupDialogOpen(true)}>
                  <AddIcon color="primary" />
                </IconButton>

                <Input
                  type="text"
                  placeholder={I18n.get('search_user_msg')}
                  onChange={searchChanged}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Toolbar>
              <TableContainer>
                <Table size="small" sx={{ height: "100%", width: "100%" }}>
                  <TableHead>
                    <TableRow key="header">
                      {headerCells.map(
                        (column) =>
                          column.show && (
                            <TableCell
                              key={column.id}
                              style={column.style}
                              align={column.align}
                            >
                              <strong>{column.label}</strong>
                              {column.id === "UserCreateDate" && (
                                <IconButton onClick={reorder}>
                                  <Tooltip arrow title={I18n.get('order_items')}>
                                    <div>
                                      <ArrowDownwardIcon
                                        style={{
                                          fontSize: 20,
                                          color: "grey",
                                          display:
                                            order === "asc" ? "inline" : "none",
                                        }}
                                      />
                                      <ArrowUpwardIcon
                                        style={{
                                          fontSize: 20,
                                          color: "grey",
                                          display:
                                            order === "asc" ? "none" : "inline",
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                </IconButton>
                              )}
                            </TableCell>
                          )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.data
                      .filter(applyFiltering)
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow key={index}>
                          {headerCells.map((column) => {
                            if (column.show) {
                              if (column.customCell) {
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.customCell(row)}
                                  </TableCell>
                                );
                              }
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {row[column.id].toString()}
                                </TableCell>
                              );
                            }
                            return null;
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={state.data.filter(applyFiltering).length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={I18n.get("rows_per_page")}
                labelDisplayedRows={
                  ({ from, to, count }) => {
                    return '' + from + '-' + to + ` ${I18n.get("of")} ` + count
                  }
                }
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          )}
          <SignupDialog
            open={signupDialogOpen}
            handleClose={() => setSignupDialogOpen(false)}
            handleSignup={handleSignup}
          />
        </Paper>
      )}
    </>
  );
}

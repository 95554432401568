import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import FormGroup from "@mui/material/FormGroup";
import { I18n } from "aws-amplify";

export default function SignupDialog(props) {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);

  const onSignup = () => {
    if (validateEmail(email)) {
      props.handleSignup({ email: email});
      props.handleClose();
    }
  };

  const validateEmail = (email) => {
    let errorMessage = "";
    const re = /\S+@\S+\.\S+/;
    if (!re.test(email)) {
      errorMessage = I18n.get('email_error');
    }

    setEmailError(errorMessage);

    return !errorMessage;
  };


  useEffect(() => {
    if (validateEmail(email)) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [email]);

  return (
    <Dialog fullWidth maxWidth="xs" open={props.open} onClose={props.handleClose}>
      <DialogTitle>{I18n.get('sign_up_user')}</DialogTitle>
      <DialogContent>
      <FormGroup>
        <TextField
          error={!!emailError}
          autoFocus
          margin="dense"
          label={I18n.get('email_address')}
          type="email"
          fullWidth
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            validateEmail(e.target.value);
          }}
        />
        {emailError && <FormHelperText error>{emailError}</FormHelperText>}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>{I18n.get('cancel')}</Button>
        <Button onClick={onSignup} disabled={!canSubmit}>
        {I18n.get('sign_up')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

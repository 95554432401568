import { Image } from "@aws-amplify/ui-react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { I18n } from "aws-amplify";
var logo = require("../../assets/img/header/LOGO_EptaWithoutService.png")

export function Header() {

  return (
    <Box>
    <Grid container alignItems="center" justifyContent="center" spacing={2} paddingBottom="10px">
      <Grid item md={12} xs={12} textAlign="center">
        <Image alt="Logo Epta Service" height="100px" src={logo} />
      </Grid>
      <Grid item md={12} xs={12} textAlign="center">
      <Typography
        variant="h4"
        component="div"
        sx={{ flexGrow: 1, color: "White", whiteSpace: "nowrap" }}
      >
        {I18n.get("nfc_management")}
      </Typography>
      </Grid>
    </Grid>
    </Box>
  );
}
import { Heading, useTheme } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";

export function SignInHeader() {
  const { tokens } = useTheme();

  return (
    <Heading textAlign="center" level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`}>
      {I18n.get("sign_in")}
    </Heading>
  );
}

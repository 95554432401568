import * as React from "react";
import { useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SidebarDrawerList from "../components/Sidebar/SidebarDrawerList";
import MenuIcon from "@mui/icons-material/Menu";
import Footer from "../components/Footer/Footer";
import routes from "../routes.js";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Paper from "@mui/material/Paper";
import { ErrorBoundary } from "react-error-boundary";
import AdminNavbarWithDrawer from "../components/Navbars/AdminNavbarWithDrawer";
import { Outlet } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";

function errorFallback({ error, resetErrorBoundary }) {
  return (
    <Box component={Paper}>
      <Alert severity="error">
        <AlertTitle>Something went wrong:</AlertTitle>
        {error.message}
      </Alert>
    </Box>
  );
}

const drawerWidth = 240;

const openedMixin = (theme) => ({
  background: "linear-gradient(to right bottom, #984300, #d67700)",
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    duration: 500,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  background: "linear-gradient(to right bottom, #984300, #d67700)",
  transition: theme.transitions.create("width", {
    duration: 500,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  background: theme.palette.mode === "light" ? "white" : "black",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    duration: 500,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      duration: 500,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function AdminLayoutWithDrawer({ toggleDarkMode, signOut }) {
  const theme = useTheme();
  const location = useLocation();
  const mobileDevice = !useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (location?.pathname === "/admin/ready" && !mobileDevice) {
      handleDrawerOpen();
    } else {
      handleDrawerClose();
    }
  }, [location.pathname, mobileDevice]);

  React.useEffect(() => {
    document.querySelector("#root > div > main").scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  return (
    <Box className="wrapper" sx={{ display: "flex" }}>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            disabled={mobileDevice}
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              mr: 2,
              ...(open && { display: "none" }),
            }}
          >
            <Box>
              <img
                src={
                  theme.palette.mode === "dark"
                    ? require("../assets/img/header/LOGO_EptaWithoutService.png")
                    : require("../assets/img/header/LOGO_EptaWithoutService_Black.png")
                }
                width="40px"
                height="40px"
                alt="..."
              />
            </Box>
          </IconButton>
          <ErrorBoundary FallbackComponent={errorFallback}>
            <AdminNavbarWithDrawer
              toggleDarkMode={toggleDarkMode}
              signout={signOut}
            />
          </ErrorBoundary>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        ModalProps={{
          keepMounted: false,
        }}
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <MenuIcon sx={{ color: "white" }} />
            ) : (
              <MenuIcon sx={{ color: "white" }} />
            )}
          </IconButton>
          {/* <Box sx={{ mr: 3 }}> */}
          <Box sx={{ mr: 2 }}>
            <Typography
              variant={!mobileDevice ? "h6" : "body2"}
              component="div"
              sx={{ ml: 1, flexGrow: 1, color: "white" }}
            >
              {I18n.get("nfc_management")}
            </Typography>

            {/* <img
              src={require("../assets/img/header/SwitchON.png")}
              width="160px"
              alt="..."
            /> */}
          </Box>
        </DrawerHeader>
        <Divider />
        <SidebarDrawerList routes={routes} open={open} />
      </Drawer>
      <Box component="main" className="main-panel" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        <Box className="content">
          <Outlet />
        </Box>
        <Box sx={{ mt: 5 }}>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default withAuthenticator(AdminLayoutWithDrawer, {
  signUpConfig: {
    hiddenDefaults: ["phone_number"],
    signUpFields: [
      { label: "Name", key: "name", required: true, type: "string" },
    ],
  },
});
